<template>
  <b-row class="mb-5">
    <b-col cols="12" offset-sm="1" sm="11">
      <h3 class="text-uppercase">{{ $t('settings.personalization.values') }}</h3>
      <p>{{ $t('settings.personalization.valuesDesc') }}</p>
    </b-col>
    <b-col cols="12" offset-sm="1" sm="11">
      <div class="value-collapse">
        <div
          class="collapse-header text-white py-2 px-3 d-flex justify-content-between"
          v-b-toggle.collapse-value
        >
          <span>{{ $t('settings.personalization.editValues') }}</span>
          <b-icon
            v-if="!visible"
            icon="chevron-right"
            class="mr-2 top-4 position-relative"
            style="color: #777c7f"
            aria-hidden="true"
            scale="1.2"
          ></b-icon>
          <b-icon
            v-else
            icon="chevron-down"
            class="mr-2 top-4 position-relative"
            aria-hidden="true"
            scale="1.2"
          ></b-icon>
        </div>
        <b-collapse id="collapse-value" v-model="visible" class="px-3">
          <div class="pt-3">
            <b-table
              :fields="itemFields"
              :items="items"
              :key="itemsUpdateField"
              show-empty
              borderless
              dark
              class="table-view items-table"
            >
              <template #table-colgroup="scope">
                <col
                  v-for="field in scope.fields"
                  :key="field.key"
                  :style="{ width: field.width }"
                >
              </template>
              <template #empty="scope">
                <p class="my-2">{{ $t('settings.personalization.table.emptyValues') }}</p>
              </template>
              <template #cell(order)="data">
                {{ data.index + 1 }}
              </template>
              <template #cell(name)="data">
            <span :id="`tooltip-name-${data.item.id}`">
            {{ `${data.item.name} (${data.item.id})` | sliceStingPos(23) }}
            </span>
                <b-tooltip
                  v-if="data.item.name.length > 23"
                  :target="`tooltip-name-${data.item.id}`"
                >
                  {{ data.item.name }}
                </b-tooltip>
              </template>
              <template #cell(type)="data">
                {{ $t(`settings.personalization.type.${data.item.type}`) }}
              </template>
              <template #cell(dot)="data">
                <b-dropdown
                  v-if="hasWriteAccess"
                  class="context-menu"
                  toggle-class="dot-button"
                  no-caret
                  dropup
                  no-flip
                  right
                >
                  <template #button-content>
                    <b-icon icon="three-dots-vertical"
                            aria-hidden="true" scale=".8"
                    ></b-icon>
                  </template>
                  <b-dropdown-item
                    @click="openDeleteModal(data.item.id)"
                  >
                    {{ $t('modals.deleteTeamItem.dropdown') }}
                  </b-dropdown-item>
                </b-dropdown>
              </template>
            </b-table>
          </div>
          <div
            v-if="hasWriteAccess"
            class="new-value-box px-3 py-3 mb-4">
            <b-row>
              <b-col cols="8">
                <h3 class="mb-0">{{ $t('settings.personalization.value.title') }}</h3>
                <p class="mb-0" v-html="$t('settings.personalization.value.desc')"></p>
              </b-col>
              <b-col cols="4" class="d-flex align-items-center justify-content-center">
                <b-button
                  class="btn px-4 green-button-transparent border-0 btn-secondary text-center"
                  @click="showCreateModal = true"
                >
                  <b-icon
                    icon="plus-circle-fill"
                    class="mr-2 top--3"
                    aria-hidden="true"
                    scale="1"
                  ></b-icon>
                  {{ $t('settings.personalization.value.add') }}
                </b-button>
              </b-col>
            </b-row>
          </div>
        </b-collapse>
      </div>
      <modal-create-new-value
        v-if="showCreateModal"
        :set-id="setId"
        :schema="schema"
        @create-value="createValueInSchema"
        @close-create-value="showCreateModal = false"
      />
    </b-col>
    <b-col cols="12" offset-sm="1" sm="11" v-if="items && items.length > 0">
      <modal-delete
        v-if="showDeleteModal"
        @delete-element="deleteValue"
        @close-modal="showDeleteModal = false"
      />
    </b-col>
  </b-row>
</template>

<script>

export default {
  name: 'PersonalizationValues',
  components: {
    ModalCreateNewValue: () => import('@/components/modals/personalization/ModalCreateNewValue.vue'),
    ModalDelete: () => import('@/components/modals/personalization/ModalDeletePersonalizationValue.vue'),
  },
  props: {
    setId: {
      type: Number,
      required: true,
    },
    schema: {
      type: Array,
      default: null,
    },
    hasWriteAccess: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    items() {
      console.log(this.schema, 'schema');
      return this.schema;
    },
  },
  data() {
    return {
      visible: false,
      itemsUpdateField: 0,
      itemFields: [
        {
          key: 'order',
          label: this.$t('settings.personalization.table.order'),
          sortable: false,
          width: '50px',
          tdClass: 'order_column',
        },
        {
          key: 'name',
          label: this.$t('settings.personalization.table.name'),
          sortable: false,
          width: '200px',
          tdClass: 'name_column',
        },
        {
          key: 'type',
          label: this.$t('settings.personalization.table.type'),
          sortable: false,
          width: 'auto',
          tdClass: 'type_column',
        },
        {
          key: 'dot',
          label: '',
          sortable: false,
          width: '40px',
        },
      ],
      showDeleteModal: false,
      showCreateModal: false,
      idToDelete: null,
    };
  },
  filters: {
    sliceStingPos(value, length) {
      return (value.length > length) ? `${value.slice(0, length)}...` : value;
    },
  },
  methods: {
    openDeleteModal(id) {
      this.showDeleteModal = true;
      this.idToDelete = id;
    },
    createValueInSchema(value) {
      this.$emit('create-value-in-schema', value);
      this.showCreateModal = false;
    },
    deleteValue() {
      if (this.idToDelete) {
        this.$emit('delete-value', this.idToDelete);
        this.idToDelete = null;
      }
      this.showDeleteModal = false;
    },
  },
};
</script>

<style scoped lang="scss">
.value-collapse {
  border: 1px solid $webmag-grey;
  .collapse-header {
    &.not-collapsed {
      background-color: $webmag-grey;
    }
  }
}
.new-value-box {
  background-color: #53575B;
  p {
    color: #fff;
    font-size: 12px;
  }
}
.top-4 {
  top: 4px;
}
</style>
