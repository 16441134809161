var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-row",
    { staticClass: "mb-5" },
    [
      _c("b-col", { attrs: { cols: "12", "offset-sm": "1", sm: "11" } }, [
        _c("h3", { staticClass: "text-uppercase" }, [
          _vm._v(_vm._s(_vm.$t("settings.personalization.values")))
        ]),
        _c("p", [_vm._v(_vm._s(_vm.$t("settings.personalization.valuesDesc")))])
      ]),
      _c(
        "b-col",
        { attrs: { cols: "12", "offset-sm": "1", sm: "11" } },
        [
          _c(
            "div",
            { staticClass: "value-collapse" },
            [
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "b-toggle",
                      rawName: "v-b-toggle.collapse-value",
                      modifiers: { "collapse-value": true }
                    }
                  ],
                  staticClass:
                    "collapse-header text-white py-2 px-3 d-flex justify-content-between"
                },
                [
                  _c("span", [
                    _vm._v(
                      _vm._s(_vm.$t("settings.personalization.editValues"))
                    )
                  ]),
                  !_vm.visible
                    ? _c("b-icon", {
                        staticClass: "mr-2 top-4 position-relative",
                        staticStyle: { color: "#777c7f" },
                        attrs: {
                          icon: "chevron-right",
                          "aria-hidden": "true",
                          scale: "1.2"
                        }
                      })
                    : _c("b-icon", {
                        staticClass: "mr-2 top-4 position-relative",
                        attrs: {
                          icon: "chevron-down",
                          "aria-hidden": "true",
                          scale: "1.2"
                        }
                      })
                ],
                1
              ),
              _c(
                "b-collapse",
                {
                  staticClass: "px-3",
                  attrs: { id: "collapse-value" },
                  model: {
                    value: _vm.visible,
                    callback: function($$v) {
                      _vm.visible = $$v
                    },
                    expression: "visible"
                  }
                },
                [
                  _c(
                    "div",
                    { staticClass: "pt-3" },
                    [
                      _c("b-table", {
                        key: _vm.itemsUpdateField,
                        staticClass: "table-view items-table",
                        attrs: {
                          fields: _vm.itemFields,
                          items: _vm.items,
                          "show-empty": "",
                          borderless: "",
                          dark: ""
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "table-colgroup",
                            fn: function(scope) {
                              return _vm._l(scope.fields, function(field) {
                                return _c("col", {
                                  key: field.key,
                                  style: { width: field.width }
                                })
                              })
                            }
                          },
                          {
                            key: "empty",
                            fn: function(scope) {
                              return [
                                _c("p", { staticClass: "my-2" }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t(
                                        "settings.personalization.table.emptyValues"
                                      )
                                    )
                                  )
                                ])
                              ]
                            }
                          },
                          {
                            key: "cell(order)",
                            fn: function(data) {
                              return [
                                _vm._v(" " + _vm._s(data.index + 1) + " ")
                              ]
                            }
                          },
                          {
                            key: "cell(name)",
                            fn: function(data) {
                              return [
                                _c(
                                  "span",
                                  {
                                    attrs: {
                                      id: `tooltip-name-${data.item.id}`
                                    }
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm._f("sliceStingPos")(
                                            `${data.item.name} (${data.item.id})`,
                                            23
                                          )
                                        ) +
                                        " "
                                    )
                                  ]
                                ),
                                data.item.name.length > 23
                                  ? _c(
                                      "b-tooltip",
                                      {
                                        attrs: {
                                          target: `tooltip-name-${data.item.id}`
                                        }
                                      },
                                      [
                                        _vm._v(
                                          " " + _vm._s(data.item.name) + " "
                                        )
                                      ]
                                    )
                                  : _vm._e()
                              ]
                            }
                          },
                          {
                            key: "cell(type)",
                            fn: function(data) {
                              return [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$t(
                                        `settings.personalization.type.${data.item.type}`
                                      )
                                    ) +
                                    " "
                                )
                              ]
                            }
                          },
                          {
                            key: "cell(dot)",
                            fn: function(data) {
                              return [
                                _vm.hasWriteAccess
                                  ? _c(
                                      "b-dropdown",
                                      {
                                        staticClass: "context-menu",
                                        attrs: {
                                          "toggle-class": "dot-button",
                                          "no-caret": "",
                                          dropup: "",
                                          "no-flip": "",
                                          right: ""
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "button-content",
                                              fn: function() {
                                                return [
                                                  _c("b-icon", {
                                                    attrs: {
                                                      icon:
                                                        "three-dots-vertical",
                                                      "aria-hidden": "true",
                                                      scale: ".8"
                                                    }
                                                  })
                                                ]
                                              },
                                              proxy: true
                                            }
                                          ],
                                          null,
                                          true
                                        )
                                      },
                                      [
                                        _c(
                                          "b-dropdown-item",
                                          {
                                            on: {
                                              click: function($event) {
                                                return _vm.openDeleteModal(
                                                  data.item.id
                                                )
                                              }
                                            }
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.$t(
                                                    "modals.deleteTeamItem.dropdown"
                                                  )
                                                ) +
                                                " "
                                            )
                                          ]
                                        )
                                      ],
                                      1
                                    )
                                  : _vm._e()
                              ]
                            }
                          }
                        ])
                      })
                    ],
                    1
                  ),
                  _vm.hasWriteAccess
                    ? _c(
                        "div",
                        { staticClass: "new-value-box px-3 py-3 mb-4" },
                        [
                          _c(
                            "b-row",
                            [
                              _c("b-col", { attrs: { cols: "8" } }, [
                                _c("h3", { staticClass: "mb-0" }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t(
                                        "settings.personalization.value.title"
                                      )
                                    )
                                  )
                                ]),
                                _c("p", {
                                  staticClass: "mb-0",
                                  domProps: {
                                    innerHTML: _vm._s(
                                      _vm.$t(
                                        "settings.personalization.value.desc"
                                      )
                                    )
                                  }
                                })
                              ]),
                              _c(
                                "b-col",
                                {
                                  staticClass:
                                    "d-flex align-items-center justify-content-center",
                                  attrs: { cols: "4" }
                                },
                                [
                                  _c(
                                    "b-button",
                                    {
                                      staticClass:
                                        "btn px-4 green-button-transparent border-0 btn-secondary text-center",
                                      on: {
                                        click: function($event) {
                                          _vm.showCreateModal = true
                                        }
                                      }
                                    },
                                    [
                                      _c("b-icon", {
                                        staticClass: "mr-2 top--3",
                                        attrs: {
                                          icon: "plus-circle-fill",
                                          "aria-hidden": "true",
                                          scale: "1"
                                        }
                                      }),
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$t(
                                              "settings.personalization.value.add"
                                            )
                                          ) +
                                          " "
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e()
                ]
              )
            ],
            1
          ),
          _vm.showCreateModal
            ? _c("modal-create-new-value", {
                attrs: { "set-id": _vm.setId, schema: _vm.schema },
                on: {
                  "create-value": _vm.createValueInSchema,
                  "close-create-value": function($event) {
                    _vm.showCreateModal = false
                  }
                }
              })
            : _vm._e()
        ],
        1
      ),
      _vm.items && _vm.items.length > 0
        ? _c(
            "b-col",
            { attrs: { cols: "12", "offset-sm": "1", sm: "11" } },
            [
              _vm.showDeleteModal
                ? _c("modal-delete", {
                    on: {
                      "delete-element": _vm.deleteValue,
                      "close-modal": function($event) {
                        _vm.showDeleteModal = false
                      }
                    }
                  })
                : _vm._e()
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }